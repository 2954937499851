import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Text, Heading } from 'components/shared/typography'
import Button from 'components/shared/button'

import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  heading: string
  email: string
}

const Section = styled.section`
  padding: 20px 0 0 0;

  ${({ theme }) => theme.media.lg.min} {
    padding: 0;
  }
`

const InnerWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
    gap: 100px;
    justify-content: center;
  }
`

const ContactItemWrapper = styled.div``

const StyledButton = styled(Button)`
  text-align: center;
  ${({ theme }) => theme.media.lg.min} {
    min-width: 250px;
  }
`

const StyledBottomText = styled(Text)`
  padding: 30px 0 20px;
`

const Contact: React.FC<Props> = ({ heading, email }) => {
  const { lg } = useBreakpoint()

  return (
    <Section>
      <Container>
        <Text
          size={lg ? 28 : 24}
          weight={500}
          themecolor="black"
          align="center"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <InnerWrapper>
          <ContactItemWrapper>
            <Heading
              size={28}
              weight={600}
              align="center"
              themecolor="primary"
              margin="15px"
            >
              Części
              <br />
              zamienne
            </Heading>
            <StyledButton as="a" href="/gdzie-kupic/" primary>
              Wybierz dystrybutora
              <br /> ze swojej okolicy
            </StyledButton>
          </ContactItemWrapper>
          <ContactItemWrapper>
            <Heading
              size={28}
              weight={600}
              align="center"
              themecolor="primary"
              margin="15px"
            >
              Naprawa
              <br />
              pogwarancyjna
            </Heading>
            <StyledButton as="a" href="/gdzie-kupic/" primary>
              Wybierz dystrybutora
              <br /> ze swojej okolicy
            </StyledButton>
          </ContactItemWrapper>
        </InnerWrapper>
        <Text size={lg ? 18 : 16} margin="10px" align="center">
          W innych przypadkach napisz na adres
        </Text>
        <Text
          as="a"
          size={28}
          align="center"
          themecolor="primary"
          href={`mailto:${email}`}
        >
          {email}
        </Text>
        <StyledBottomText size={28} align="center" weight={700}>
          Chętnie pomożemy
        </StyledBottomText>
      </Container>
    </Section>
  )
}

export default Contact
