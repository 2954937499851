import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import About from 'components/layout/Sections/Pages/SerwisOgrodzen/About'
import Steps from 'components/layout/Sections/Pages/SerwisOgrodzen/Steps'

import Contact from 'components/layout/Sections/Pages/SerwisOgrodzen/Contact'
import Navigation from 'components/layout/Navigation/index'

const breadcrumbs = {
  mobile: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Serwis ogrodzeń',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Serwis ogrodzeń',
    },
  ],
}

const contactHeading = 'Nie pamiętasz, u którego dystrybutora dokonałeś zakupu?'

const contactEmail = 'biuro@gatigo.pl'

const SerwisOgrodzenPage: React.FC<PageProps> = ({ data }) => {
  const PAGE = data?.wpPage?.serwisOgrodzen
  const PAGE_SEO = data?.wpPage?.seo

  const STEPS = React.useMemo(
    () =>
      PAGE?.serviceStepsComplaint?.map((step) => ({
        label: step?.serviceStepsComplaintText,
      })),
    []
  )

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={
          PAGE?.serviceHeroImage?.localFile?.childImageSharp?.gatsbyImageData
        }
        imgDesktop={
          PAGE?.serviceHeroImage?.localFile?.childImageSharp?.gatsbyImageData
        }
        headingMobile={PAGE?.serviceTitle}
        headingDesktop={PAGE?.serviceTitle}
        breadcrumbs={breadcrumbs}
      />
      <About description={PAGE?.serviceText} downloadLink={PAGE?.serviceFile} />
      <Steps steps={STEPS} />
      <Contact heading={contactHeading} email={contactEmail} />
    </Layout>
  )
}

export default SerwisOgrodzenPage

export const query = graphql`
  query SerwisOgrodzenPage {
    wpPage(slug: { regex: "/serwis-ogrodzen/" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      id
      serwisOgrodzen {
        fieldGroupName
        serviceTitle
        serviceText
        serviceFile
        serviceStepsComplaint {
          serviceStepsComplaintText
        }
        serviceHeroImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
