import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'

import icon1 from 'assets/icons/step-1.png'

type Step = {
  label: string
}

type Props = {
  steps: Step[]
}

const Section = styled.section`
  position: relative;
  background-color: #f5f5f5;
  padding: 60px 0 0;
  z-index: 2;

  ${({ theme }) => theme.media.lg.min} {
    background-color: ${({ theme }) => theme.colors.white};
    padding: 60px 0 0 0;
  }
`

const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
    justify-content: space-around;
    max-width: 1000px;
  }

  ${({ theme }) => theme.media.xl.min} {
    max-width: 1200px;
  }
`

const StepIconWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;

  & > img {
    position: relative;
    max-height: 100px;
    z-index: 2;
  }
`

const StepNumber = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  color: ${({ theme }) => theme.colors.gray};
  font-size: 185px;
  font-weight: 800;
  z-index: 1;
`

const StepSingleWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 75px;

  & > ${Text} {
    position: relative;
    z-index: 2;
  }

  :nth-child(1) {
    & > ${StepIconWrapper} {
      & > ${StepNumber} {
        top: 35px;
        left: -50px;
      }
    }
  }
  :nth-child(2) {
    & > ${StepIconWrapper} {
      & > ${StepNumber} {
        top: 35px;
        left: -65px;
      }
    }
  }
  :nth-child(3) {
    & > ${StepIconWrapper} {
      transform: translateX(10px);
      & > ${StepNumber} {
        top: 35px;
        left: -65px;
      }
    }
  }

  :nth-child(4) {
    & > ${StepIconWrapper} {
      & > ${StepNumber} {
        top: 40px;
        left: -80px;
      }
    }
  }

  ${({ theme }) => theme.media.lg.min} {
    width: 250px;

    :nth-child(1) {
      & > ${StepIconWrapper} {
        & > ${StepNumber} {
          left: -39px;
        }
      }
    }
    :nth-child(3) {
      & > ${StepIconWrapper} {
        transform: translateX(10px);
        & > ${StepNumber} {
          top: 33px;
          left: -60px;
        }
      }
    }

    :nth-child(4) {
      & > ${StepIconWrapper} {
        & > ${StepNumber} {
          top: 40px;
          left: -65px;
        }
      }
    }
  }

  ${({ theme }) => theme.media.xxl.min} {
    width: 380px;
  }
`

const Steps: React.FC<Props> = ({ steps }) => {
  return (
    <Section>
      <Container>
        <StepsWrapper>
          {steps.map(({ label }, index) => (
            <StepSingleWrapper key={label}>
              <StepIconWrapper>
                <img src={icon1} alt="icon" />
                <StepNumber>{index + 1}</StepNumber>
              </StepIconWrapper>
              <Text
                size={15}
                weight={500}
                themecolor="black"
                align="center"
                dangerouslySetInnerHTML={{ __html: label }}
              />
            </StepSingleWrapper>
          ))}
        </StepsWrapper>
      </Container>
    </Section>
  )
}

export default Steps
