import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'
import Button from 'components/shared/button'

import useBreakpoint from 'hooks/useBreakpoint'

import arrowRightIcon from 'assets/icons/arrow-right-white.svg'

type Props = {
  description: string
  downloadLink: string
}

const Section = styled.section`
  padding: 20px 0 40px 0;

  ${({ theme }) => theme.media.lg.min} {
    padding: 0 0 40px 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 20px 30px;
  align-self: center;
  text-transform: uppercase;
  font-size: 24px;

  p {
    margin-bottom: 0;
  }

  img {
    max-height: 20px;
  }

  ${({ theme }) => theme.media.lg.min} {
    font-size: 28px;
  }
`

const About: React.FC<Props> = ({ description, downloadLink }) => {
  const { lg } = useBreakpoint()

  return (
    <Section>
      <Container>
        <Wrapper>
          <Text
            size={lg ? 28 : 24}
            weight={500}
            themecolor="black"
            align={lg ? 'left' : 'center'}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <StyledButton
            primary
            as="a"
            href={downloadLink}
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            <p>pobierz</p>
            <img src={arrowRightIcon} alt="" />
          </StyledButton>
        </Wrapper>
      </Container>
    </Section>
  )
}

export default About
